import React from 'react';
import EventiTest from "./components/Eventi";

function MyCalendar() {

    return (
        <EventiTest/>
    );
}

export default MyCalendar;
