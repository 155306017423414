import {BackButton} from "../components/UI/BackButton";
import React from "react";

import call from "../assets/callgo-fano.png"
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";
import {BackButtonWhite} from "../components/UI/BackButtonWhite";
export const Contattaci = () => {
    const isDesktop = useMediaQuery({
        query: '(min-width: 1024px)'
    });
    const {t} = useTranslation()

    return (
        <section className="bg-isdfBlue h-screen text-left w-full">
            <div className={"flex flex-wrap h-[16vh] content-center relative z-10 p-5"}>
                <div className={"flex flex-wrap items-center"}>
                    <BackButton sfondo={"false"} />
                    <span className={"text-white font-semibold text-[20px] ml-4"}>{t('contattaci')}</span>
                </div>

            </div>
            <div className={'lg:h-[74vh]'}>

            <p className={"text-white font-semibold text-[16px] ml-4"}>
                {t('testocontattaci1')} <strong>Call & Go</strong>.
            </p>
            <img src={`${call}`} className={"w-24 h-24 inline-block ml-4 mt-4"}/>
            {isDesktop ?
                <a className={'bg-white px-5 py-2 ml-4 mt-4 inline-block rounded-6px'}>{t('3663426985')}</a>
                :
                <a className={'bg-white px-5 py-2 ml-4 mt-4 inline-block rounded-6px'}>{t('3663426985')}</a>
            }
            <p className={"text-white font-semibold text-[16px] ml-4 mt-5"}>
                {t('testocontattaci4')} <a className={'underline'} href={'mailto:turismo@comune.fano.pu.it'}>turismo@comune.fano.pu.it</a>
            </p>
            <p className={"text-white font-semibold text-[16px] ml-4 mt-5 text-right mr-5"}>
                {t('testocontattaci5')}
            </p>
            </div>

            {isDesktop ?
                <div className={'bg-isdfBlue h-[10vh] b-t-l-r-15 content-center flex flex-wrap items-center p-8'}>
                    <BackButtonWhite sfondo={'true'}/>
                </div>
                :
                <div className={'hidden'}/>
            }
        </section>
    );
};