import React, {useState} from "react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";


const GoToDirection = (props) => {

    const [currentPosition, setCurrentPosition] = useState(null);

    useEffect(() => {

    // const clickCurrentPosition = () =>{
        navigator.geolocation.getCurrentPosition(function(position) {
            // console.log("Latitude is :", position.coords.latitude);
            // console.log("Longitude is :", position.coords.longitude);
            setCurrentPosition([position.coords.latitude, position.coords.longitude])
        });
    // }
    }, [navigator]);

    const { t } = useTranslation()

    return(
        // <div hidden={true}>

            <a
                // onClick={clickCurrentPosition}
                className='mb-6 inline-flex bg-isdfBlue justify-center rounded-[15px] bg-opacity-100 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-75 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 z-10 lg:hidden'
                target='_blank'
                href={`https://maps.apple.com/?saddr=${currentPosition}&daddr=${props.latitudine},${props.longitudine}`}>
                {/*href={`https://maps.google.com/maps/dir/${currentPosition}/${props.latitudine},${props.longitudine}`}>*/}
                <svg className='mt-1 mr-2' id="Icon_map-location-arrow" data-name="Icon map-location-arrow" xmlns="http://www.w3.org/2000/svg" width="19.254" height="18" viewBox="0 0 19.254 18">
                    <path id="Icon_map-location-arrow-2" data-name="Icon map-location-arrow" d="M13.556,19.581l-2.773-8.275H1.773L19.1,1.581Z" transform="translate(-1.773 -1.581)" fill="#fff"/>
                </svg>
                {t("portami")}
            </a>
        // </div>
    )

}

export default GoToDirection;
