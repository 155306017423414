import {Link} from "react-router-dom";
import {useEffect, useState} from "react";

import menuIcon from "../../assets/icons/menuOpen.svg"
import comuneETerritorio from "../../assets/icons/comune_e_territorio.svg"
import mappaDellaCitta from "../../assets/icons/mappa_della_citta.svg"
import eventiERassegne from "../../assets/icons/eventi_e_rassegne.svg"
import itinerariTuristici from "../../assets/icons/itinerari_turistici.svg"
import museiECultura  from "../../assets/icons/musei_e_cultura.svg"
import ospitalitaESapori from "../../assets/icons/ospitalita_e_sapori.svg"

import './Menu.css'
import axios from "../api";

const Menu = () => {

    const currentLanguage = localStorage.getItem('i18nextLng');
    // alert(currentLanguage)

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [height, setHeight] = useState('0');
    const [top, setTop] = useState('[540px]');
    const [padding, setPadding] = useState('0');
    const [paddingTop, setPaddingTop] = useState('0');
    const [paddingBottom, setPaddingBottom] = useState('0');

    function toggleHeight() {
        setHeight(height === '0' ? '57vh' : '0');
        setTop(top === '[540px]' ? '0' : '[540px]');
        setPadding(padding === '0' ? '5' : '0');
        setPaddingTop(paddingTop === '0' ? '4' : '0');
        setPaddingBottom(paddingBottom === '0' ? '4' : '0');
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        toggleHeight();
    }


    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get(`https://backend.isoladellefemmine.bbsitalia.com/${currentLanguage}/api/menu`)
            .then(response => setData(response.data))
            .catch(error => console.log(error));
    }, []);


    return(
        <div className='menu h-[20vh] overflow-hidden'>
            <div
                className={`${isMenuOpen ? '' : 'hidden' } fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full`}
                id="my-modal"
                onClick={toggleMenu}
            ></div>
            <div className='absolute text-center w-full bottom-0 overflow-hidden bg-white b-t-l-r-15 pt-5 pl-5 pr-5'>
                {/*{isMenuOpen && (*/}
                    <ul className={`h-${height} top-${top} z-10 relative items-center flex flex-wrap duration-300 pt-${paddingTop} pb-${paddingBottom}`}>
                        {/*<li className={`basis-1/3 px-2 -mb-5`}*/}
                        {/*    onClick={toggleMenu}*/}
                        {/*>*/}
                        {/*    <Link className={'bg-amber-300 w-full h-full flex justify-center items-center items-center rounded-2xl'} to="/"><span>Home</span></Link>*/}
                        {/*</li>*/}
                        {data && data?.map((item, index) => {
                            return (
                                <li className={`flex w-1/3 h-32 pb-3 px-2`}
                                    onClick={toggleMenu}
                                    key={index}
                                >
                                    {/*<Link className={'bg-white shadow-isdfShadow w-full h-full flex flex-wrap justify-center items-center rounded-2xl'}>*/}
                                    {/*    <img src={`https://backend.isoladellefemmine.bbsitalia.com${item.icona}`} className={'w-[40px]'} alt={item.titolo}/>*/}
                                    {/*    <span className={`w-full font-roboto_slab text-isdfBlue text-[15px] font-bold leading-6 px-3`}>{item.titolo}</span>*/}
                                    {/*</Link>*/}
                                    {currentLanguage === 'it' && (
                                        <Link className={'bg-white shadow-isdfShadow w-full h-full flex flex-wrap justify-center items-center rounded-2xl'} to={item.url}>
                                            <img src={`https://backend.isoladellefemmine.bbsitalia.com${item.icona}`} className={'w-[40px]'} alt={item.titolo}/>
                                            <span className={`w-full font-roboto_slab text-isdfBlue text-[15px] font-bold leading-5 px-3`}>{item.titolo}</span>
                                        </Link>
                                    )}

                                    {currentLanguage === 'en' && (
                                        <Link className={'bg-white shadow-isdfShadow w-full h-full flex flex-wrap justify-center items-center rounded-2xl'} to={item.url}>
                                            <img src={`https://backend.isoladellefemmine.bbsitalia.com${item.icona}`} className={'w-[40px]'} alt={item.titolo}/>
                                            <span className={`w-full font-roboto_slab text-isdfBlue text-[13px] font-bold leading-5 px-3`}>{item.titolo}</span>
                                        </Link>
                                    )}
                                </li>
                            )
                        })}

                        {/*<li className={`flex w-1/3 h-32 pb-3 px-2`}*/}
                        {/*    onClick={toggleMenu}*/}
                        {/*>*/}
                        {/*    <Link className={'bg-white shadow-isdfShadow w-full h-full flex flex-wrap justify-center items-center items-center rounded-2xl'} to="/comune-e-territorio">*/}
                        {/*        <img src={comuneETerritorio} className={'w-[40px]'} alt="Comune e Territorio"/>*/}
                        {/*        <span className={'w-full font-roboto_slab text-isdfBlue text-[18px] font-bold leading-6 px-3'}>Comune e Territorio</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*<li className={`flex w-1/3 h-32 pb-3 px-2`}*/}
                        {/*    onClick={toggleMenu}*/}
                        {/*>*/}
                        {/*    <Link className={'bg-white shadow-isdfShadow w-full h-full flex flex-wrap justify-center items-center rounded-2xl'} to="/mappa-della-citta">*/}
                        {/*        <img src={mappaDellaCitta} className={'w-[40px]'} alt="Mappa della cittá"/>*/}
                        {/*        <span className={'w-full font-roboto_slab text-isdfBlue text-[18px] font-bold leading-6 px-3'}>Mappa della cittá</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*<li className={`flex w-1/3 h-32 pb-3 px-2`}*/}
                        {/*    onClick={toggleMenu}*/}
                        {/*>*/}
                        {/*    <Link className={'bg-white shadow-isdfShadow w-full h-full flex flex-wrap justify-center items-center rounded-2xl'} to="/eventi-e-rassegne">*/}
                        {/*        <img src={eventiERassegne} className={'w-[40px]'} alt="Eventi e Rassegne"/>*/}
                        {/*        <span className={'w-full font-roboto_slab text-isdfBlue text-[18px] font-bold leading-6 px-3'}>Eventi e Rassegne</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*<li className={`flex w-1/3 h-32 pb-3 px-2`}*/}
                        {/*    onClick={toggleMenu}*/}
                        {/*>*/}
                        {/*    <Link className={'bg-white shadow-isdfShadow w-full h-full flex flex-wrap justify-center items-center rounded-2xl'} to="/itinerari-turistici">*/}
                        {/*        <img src={itinerariTuristici} className={'w-[40px]'} alt="Itinerari turistici"/>*/}
                        {/*        <span className={'w-full font-roboto_slab text-isdfBlue text-[18px] font-bold leading-6 px-3'}>Itinerari turistici</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*<li className={`flex w-1/3 h-32 pb-3 px-2`}*/}
                        {/*    onClick={toggleMenu}*/}
                        {/*>*/}
                        {/*    <Link className={'bg-white shadow-isdfShadow w-full h-full flex flex-wrap justify-center items-center rounded-2xl'} to="/musei-e-cultura">*/}
                        {/*        <img src={museiECultura} className={'w-[40px]'} alt="Musei e cultura"/>*/}
                        {/*        <span className={'w-full font-roboto_slab text-isdfBlue text-[18px] font-bold leading-6 px-3'}>Musei e cultura</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*<li className={`flex w-1/3 h-32 pb-3 px-2`}*/}
                        {/*    onClick={toggleMenu}*/}
                        {/*>*/}
                        {/*    <Link className={'bg-white shadow-isdfShadow w-full h-full flex flex-wrap justify-center items-center rounded-2xl'} to="/ospitalita-e-sapori">*/}
                        {/*        <img src={ospitalitaESapori} className={'w-[40px]'} alt="Ospitalitá e Sapori"/>*/}
                        {/*        <span className={'w-full font-roboto_slab text-isdfBlue text-[18px] font-bold leading-6 px-3'}>Ospitalitá e Sapori</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                    </ul>
                {/*)}*/}
                <button className={`bg-white pb-5 w-full text-center relative z-10`} onClick={toggleMenu}>
                    <div className={' w-16 h-16 m-auto rounded-full border-[4px] flex justify-center content-center align-middle flex-wrap items-center border-isdfBlue'}>
                        <p className={'text-isdfBlue font-semibold'}>Menù</p>
                    </div>
                    {/*<img src={menuIcon} className={`w-full w-16 h-16 m-auto rotate-0 transition-all ease-linear ${isMenuOpen ? 'rotate-180' : '' }`}/>*/}
                </button>
            </div>


        </div>
    )
}

export default Menu;
